import React, { useEffect, useState } from "react";
import useContactId from './useContactId';
//import CCPPanel from './CCPPanel';

import Select from "../UI/Select";
import Input from "../UI/Input";
import Button from "../UI/Button";

import { useForm } from "../Hooks/form-hook";
import { useHttpClient } from "../Hooks/http-hook";

import {
  VALIDATOR_NUMBERS,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MINLENGTH,
} from "../UI/util/validators";

import styles from "./CCP.module.css";

function getAgentIdFromArn(agentArn) {
  if (!agentArn) return null;
  const parts = agentArn.split('/');
  return parts[parts.length - 1];
}

const SNowUrl = 'https://hexitsm.service-now.com';

const CCP = ({ agentInfo, ...props }) => {
  const [counter, setCounter] = useState(0);
  const [timer, setTimer] = useState();

  const [incidentNumber, setIncidentNumber] = useState('');
  const [error, setError] = useState('');

  const { isLoading, sendRequest } = useHttpClient();
  const [queueOptions, setQueueOptions] = useState([
    {
      value: "",
      name: "--Please Select--",
    },
  ]);

  const [countryOptions, setCountryOptions] = useState([
    {
      value: "+1",
      name: "+1 (US/Canada)",
    },
  ]);

  const [formState, inputHandler, selectHandler] = useForm(
    {
      queue: {
        value: "",
        isValid: false,
      },
      country: {
        value: "+1",
        isValid: true,
      },
      phonenumber: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const currentContactId = useContactId(window.connect);

  useEffect(() => {
    console.log('Current Contact ID:', currentContactId);
  }, [currentContactId]);


  

    useEffect(() => {
      if (agentInfo) {
        console.log("Agent ARN (CCP): ", agentInfo.agentARN);
        // Add your logic here
      }
    }, [agentInfo]);

// Function to open a new tab with an incident to update
const UpdateIncident = async (url, incidentNumber, company_name, user_sys_id, contact_id, agentARN) => {
  if (!incidentNumber.trim()) {
      setError('Please enter an incident number.');
      return;
  }

  console.log('incidentNumber: ', incidentNumber);
  console.log('company_name: ', company_name);
  console.log('user_sys_id: ', user_sys_id);
  console.log('agentARN: ', agentARN);

  // Call Lambda function to look up the incident in ServiceNow
  try {
      // Step 1: Call the API Gateway to lookup existing incident
      const API_GATEWAY_ENDPOINT = 'https://wzcjdiknul.execute-api.us-east-1.amazonaws.com/Prod/lookupincident';
      const response = await fetch(`${API_GATEWAY_ENDPOINT}?&incident_number=${encodeURIComponent(incidentNumber)}&company_name=${encodeURIComponent(company_name)}&user_sys_id=${encodeURIComponent(user_sys_id)}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      });

      if (!response.ok) {
        console.log('An error has occurred fetching data from lookupincident API ');
        throw new Error(`An error has occurred: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.status !== 'success') {
           console.log('data.status: ', data.status);
           setError(data.message);
      } else {

          const { incident_number, sys_id } = data.incident;

          console.log('Incident fetched:', {
              incidentNumber: incident_number,
              sysId: sys_id,
          });

           // Step 2: Call the Lambda function to store the contactID/incident association
    const agentId = getAgentIdFromArn(agentARN);
    console.log("agentId: ", agentId);       
    const LAMBDA_DYNAMODB_ENDPOINT = `https://wzcjdiknul.execute-api.us-east-1.amazonaws.com/Prod/storedynamodb?incidentNumber=${encodeURIComponent(incident_number)}&contactId=${encodeURIComponent(contact_id)}&sysId=${encodeURIComponent(sys_id)}&agentId=${encodeURIComponent(agentId)}`;
    console.log('LAMBDA_DYNAMODB_ENDPOINT:', LAMBDA_DYNAMODB_ENDPOINT);
    const callLambdaFunction = async () => {
      try {
            const lambdaResponse = await sendRequest(
                LAMBDA_DYNAMODB_ENDPOINT,
                'POST',
                JSON.stringify({
                  incident_number,
                  contact_id,
                  sys_id,
                  agentId,
                }),
                {
                  'Content-Type': 'application/json',
                }
            );

            // Log the lambdaResponse for debugging
            console.log('Raw Lambda Response:', lambdaResponse);

            // Check if lambdaResponse is an object with a 'result' property
            if (!lambdaResponse || typeof lambdaResponse !== 'object' || !lambdaResponse.result) {
              throw new Error('Invalid response from sendRequest');
            }

            // Assuming lambdaResponse.result contains the actual data
            const lambdaData = lambdaResponse;

            // Log the entire lambdaResponse for debugging
            console.log('Parsed Lambda Response:', lambdaData);

            // Check if the Lambda function executed successfully
            if (lambdaData.result === 'Success') {
              console.log('lambdaData.result is Success');
              
              const buildUrl = '/now/nav/ui/classic/params/target/incident.do%3Fsys_id%3D';
              const openurl = `${url}${buildUrl}${sys_id}`;
              console.log('url to open existing incident: ', openurl);
              window.open(openurl, '_blank').focus();
              setIncidentNumber('');
              setError('');
            } 
            else {
              console.error('Lambda function execution failed:', lambdaData.message || 'No error message provided');
              window.open(url, '_blank').focus();
            }
        } 
        catch (error) {
          console.error('Error calling Lambda function:', error);
        }
    };

    // Call the Lambda function with the new incident details
    await callLambdaFunction();



  }
  } catch (error) {
      console.error('Error fetching existing incident:', error);
  }
};

// Function to handle input change
const handleInputChange = (event) => {
  setIncidentNumber(event.target.value);
};

  // Effect to reset fields when the component mounts
  useEffect(() => {
    setIncidentNumber('');
    setError('');
    console.log('Component mounted: error and incidentNumber reset.');
  }, []);

// Effect to reset fields when a new call is received
useEffect(() => {
    if (props.agentInfo?.agentState?.name === "Busy") {
      setIncidentNumber('');
      setError('');
    }
  }, [props.agentInfo]);

 

// Function to call AWS Lambda and then open a new tab with a new incidentL
async function CreateNewIncident(url, contactId, customerName, customer_number, customer_sys_id, agentARN, customer_status) {
  const API_GATEWAY_ENDPOINT = 'https://wzcjdiknul.execute-api.us-east-1.amazonaws.com/Prod/createnewincident';
  //const customerName = 'Velocity'; // Use your stored customer name value here
  console.log('CreateNewIncident - contactId: ', contactId);
  console.log('CreateNewIncident - customerName: ', customerName);
  console.log('CreateNewIncident - customer_number: ', customer_number);
  console.log('CreateNewIncident - customer_sys_id: ', customer_sys_id);
  console.log('CreateNewIncident - agentARN: ', agentARN);
  console.log('CreateNewIncident - customer_status: ', customer_status);
  try {
    // Step 1: Call the API Gateway to create a new incident
    const response = await fetch(`${API_GATEWAY_ENDPOINT}?customerName=${encodeURIComponent(customerName)}&customer_number=${encodeURIComponent(customer_number)}&customer_sys_id=${encodeURIComponent(customer_sys_id)}&customer_status=${encodeURIComponent(customer_status)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`An error has occurred: ${response.statusText}`);
    }

    const data = await response.json();
    const incidentNumber = data.incident_number;
    const sysId = data.sys_id;
    const IncidentURL = data.incidentURL;

    console.log('New Incident Data:', {
      incidentNumber,
      sysId,
      contactId,
      IncidentURL,
    });

    // Step 2: Call the Lambda function to store the contactID/incident association
    //const LAMBDA_DYNAMODB_ENDPOINT = 'https://wzcjdiknul.execute-api.us-east-1.amazonaws.com/Prod/storedynamodb';


    const agentId = getAgentIdFromArn(agentARN);
    console.log("agentId: ", agentId);
    console.log('Preparing to call LAMBDA_DYNAMODB_ENDPOINT...');
    const LAMBDA_DYNAMODB_ENDPOINT = `https://wzcjdiknul.execute-api.us-east-1.amazonaws.com/Prod/storedynamodb?incidentNumber=${encodeURIComponent(incidentNumber)}&contactId=${encodeURIComponent(contactId)}&sysId=${encodeURIComponent(sysId)}&agentId=${encodeURIComponent(agentId)}`;
    console.log('LAMBDA_DYNAMODB_ENDPOINT:', LAMBDA_DYNAMODB_ENDPOINT);
    const callLambdaFunction = async () => {
      try {
        const lambdaResponse = await sendRequest(
          LAMBDA_DYNAMODB_ENDPOINT,
          'POST',
          JSON.stringify({
            incidentNumber,
            contactId,
            sysId,
          }),
          {
            'Content-Type': 'application/json',
          }
        );

        // Log the lambdaResponse for debugging
        console.log('Raw Lambda Response:', lambdaResponse);

        // Check if lambdaResponse is an object with a 'result' property
        if (!lambdaResponse || typeof lambdaResponse !== 'object' || !lambdaResponse.result) {
          throw new Error('Invalid response from sendRequest');
        }

        // Assuming lambdaResponse.result contains the actual data
        const lambdaData = lambdaResponse;

        // Log the entire lambdaResponse for debugging
        console.log('Parsed Lambda Response:', lambdaData);

        // Check if the Lambda function executed successfully
        if (lambdaData.result === 'Success') {
          console.log('lambdaData.result is Success');
          console.log('Opening IncidentURL: ', IncidentURL);
          // Open a new tab if the Lambda call was successful
          window.open(IncidentURL, '_blank').focus();
        } else {
          console.error('Lambda function execution failed:', lambdaData.message || 'No error message provided');
          window.open(url, '_blank').focus();
        }
      } catch (error) {
        console.error('Error calling Lambda function:', error);
      }
    };

    // Call the Lambda function with the new incident details
    await callLambdaFunction();
  } catch (error) {
    console.error('Error in CreateNewIncident function:', error);
  }
}


  useEffect(() => {
    setQueueOptions(props.agentQueues);
  }, [props.agentQueues]);

  useEffect(() => {
    console.log(`Country Code ====> ${formState.inputs.country.value}`);
    inputHandler("phonenumber", "", false);
  }, [formState.inputs.country.value]);

  //   console.log(`Agent State [CCP] ===> ${JSON.stringify(props.agentInfo)}`);
  //   console.log(
  //     `Agent State [CCP] ===> ${JSON.stringify(props.agentInfo?.agentState)}`
  //   );
  //   console.log(`Agent State [CCP] ===> ${props.agentInfo?.agentState?.name}`);

  useEffect(() => {
    if (!timer && props.agentInfo?.agentState?.name === "Busy") {
      setTimer(
        setInterval(() => {
          setCounter((counter) => counter + 1);
        }, 1000)
      );
    }

    if (timer && props.agentInfo?.agentState?.name === "AfterCallWork") {
      clearInterval(timer);
      setTimer();
      setCounter(0);
    }
  }, [props.agentInfo, counter, timer]);

  useEffect(() => {
    const processExe = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_AWS_ENDPOINT}/list-outboundcountries`
        );
        let countries = responseData.message.map((x) => {
          return {
            name: x.name,
            value: x.countrycode,
          };
        });

        // Sort countries by country code
        countries.sort((a, b) => a.value.localeCompare(b.value));
        
        setCountryOptions(countries);
      } catch (error) {
        console.error(`Fetch Countries [Error] ====> ${error}`);
        setCountryOptions([
          {
            value: "+1",
            name: "+1 (US/Canada)",
          },
        ]);
      }
    };
    processExe();
  }, [sendRequest]);

  const makeOutboundCallHandler = (event) => {
    event.preventDefault();
    props.makeOutboundCall(
      formState.inputs.country.value,
      formState.inputs.phonenumber.value,
      formState.inputs.queue.value
    );
    selectHandler("queue", "", false);
    selectHandler("country", "+1", true);
    inputHandler("phonenumber", "", false);
  };

  useEffect(() => {
    if (!props.queue) {
      setIncidentNumber('');
      setError('');
      console.log('useEffect props.queue: ', props.queue);
    }
  }, [props.queue]);

  const SNowUnsupportedCustomerNames = ['JUUL Labs Other', 'JUUL Labs Retail', 'Macrogenics'];
  let SNowUnsupported = false;
  let customerName = '';

  // Check if props.queue has a value assigned
  if (props.queue) {
    // extract customerName from props.queue
    customerName = props.queue;
    customerName = customerName.replace(' voice queue', '');
    console.log('Customer name: ', customerName);
    if (SNowUnsupportedCustomerNames.includes(customerName)) {
      SNowUnsupported = true;
      console.log('Customer name is SNow unsupported.');
    } else {
      SNowUnsupported = false;
      console.log('Customer name is SNow supported.');
    }
  } else {
    console.log('props.queue is not assigned a value.');
  }

  let min =
    Math.floor(counter / 60) < 10
      ? `0${Math.floor(counter / 60)}`
      : Math.floor(counter / 60);
  let sec = counter % 60 < 10 ? `0${counter % 60}` : counter % 60;
  console.log('props.queue: ', props.queue);
  console.log('props.contactAttributes: ', props.contactAttributes);
  return (
    <div className={`${styles.ccp_Container}`}>
      <div className={`${styles.ccp_AgentPanel}`}>
        {props.outboundFeature && (
          <fieldset className={`${styles.ccp_Outbound}`}>
            <legend>
              <h1>OUTBOUND CALL</h1>
            </legend>

            <form
              className={`${styles.ccp_Queues}`}
              onSubmit={makeOutboundCallHandler}>
              <div className={`${styles.ccp_Field}`}>
                <Select
                  id="queue"
                  label="Outbound Queue"
                  options={queueOptions}
                  onSelect={selectHandler}
                  value={formState.inputs.queue.value}
                  valid={formState.inputs.queue.isValid}
                />
              </div>
              <div className={`${styles.ccp_Field}`}>
                <Select
                  id="country"
                  label="Country Code"
                  options={countryOptions}
                  onSelect={selectHandler}
                  phonenumber={formState.inputs.phonenumber.value}
                  value={formState.inputs.country.value}
                  valid={formState.inputs.country.isValid}
                />
                <Input
                  id="phonenumber"
                  label="Phone Number"
                  element="input"
                  type="text"
                  validators={
                    formState.inputs.country.value === "+1"
                      ? [
                          VALIDATOR_NUMBERS,
                          VALIDATOR_MINLENGTH(10),
                          VALIDATOR_MAXLENGTH(10),
                        ]
                      : [VALIDATOR_NUMBERS, VALIDATOR_MINLENGTH(1)]
                  }
                  onInput={inputHandler}
                  countrycode={formState.inputs.country.value}
                  isDisabled={!formState.inputs.queue.isValid}
                  value={formState.inputs.phonenumber.value}
                  valid={formState.inputs.phonenumber.isValid}
                />
              </div>

              <div className={`${styles.ccp_CallButton}`}>
                <Button
                  type="submit"
                  disabled={
                    !formState.isValid || !formState.inputs.phonenumber.isValid
                  }>
                  CALL
                </Button>
              </div>
            </form>
          </fieldset>
        )}


<fieldset className={`${styles.ccp_Info}`}>
          <legend>
            <h1>INFORMATION</h1>{/*currentContactId*/}
          </legend>
          {props.agentInfo && props.agentInfo?.agentState?.name === "Busy" && (
            <div className={`${styles.cpp__Section__Timer}`}>
              <h5>On Contact:</h5>
              <h6>
                {min}:{sec}
              </h6>
            </div>
          )}

          {props.queue && (
            <div className={`${styles.cpp__Section__Header}`}>
              <h1>
                <img src="images/phone.svg" alt="phone" />
                CALL
              </h1>
            </div>
          )}
          {props.queue && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Queue Name</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                <h6>{props.queue ? props.queue : "Unavailable"}</h6>
              </div>
            </div>
          )}
          {props.queue && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Customer Name</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                <h6>
                  {props.contactAttributes.client_name
                    ? props.contactAttributes.client_name
                    : "Unavailable"}
                </h6>
              </div>
            </div>
          )}
          {props.queue && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Caller Phone Number</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                <h6>
                  {props.contactAttributes.customer_number
                    ? props.contactAttributes.customer_number.replace("+1", "")
                    : props.contactAttributes.callback_number
                    ? props.contactAttributes.callback_number.replace("+1", "")
                    : "Unavailable"}
                </h6>
              </div>
            </div>
          )}
          {props.queue && (
            <div className={`${styles.cpp__Section__Header}`}>
              <h1>
                <img
                  src="images/person.svg"
                  style={{ height: "1.2rem" }}
                  alt="person"
                />
                CUSTOMER
              </h1>
            </div>
          )}
          {props.queue && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Name</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                {!props.contactAttributes.first_name &&
                  props.contactAttributes.last_name && (
                    <h6>{props.contactAttributes.last_name}</h6>
                  )}
                {props.contactAttributes.first_name &&
                  !props.contactAttributes.last_name && (
                    <h6>{props.contactAttributes.first_name}</h6>
                  )}
                {!props.contactAttributes.first_name &&
                  !props.contactAttributes.last_name && <h6>Unavailable</h6>}
                {props.contactAttributes.first_name &&
                  props.contactAttributes.last_name && (
                    <h6>
                      {props.contactAttributes.first_name}&nbsp;
                      {props.contactAttributes.last_name}
                    </h6>
                  )}
              </div>
            </div>
          )}
          {props.queue && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Title</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                <h6>
                  {props.contactAttributes.title
                    ? props.contactAttributes.title
                    : "Unavailable"}
                </h6>
              </div>
            </div>
          )}
          {props.queue && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Specialty Group</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                <h6>
                  {props.contactAttributes.u_specialty_group
                    ? props.contactAttributes.u_specialty_group
                    : "Unavailable"}
                </h6>
              </div>
            </div>
          )}
          {props.queue && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Status</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
              <h6 style={{ color: props.contactAttributes.active === "false" ? "red" : props.contactAttributes.active === "true" ? "green" : "inherit" }}> 
                  {props.contactAttributes.active === "false" ? "Inactive" : props.contactAttributes.active === "true" ? "Active" : "Unavailable"}
                </h6>
              </div>
            </div>
          )}


          {/*props.queue && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Current ContactID</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
               <h6>
                  {currentContactId
                    ? currentContactId
                    : "Unavailable"}
                </h6>               
                <h6>
                  {props.contactAttributes.ContactID
                    ? props.contactAttributes.ContactID
                    : "Unavailable"}
                </h6>
              </div>
            </div>
            )*/}
          
            <p></p>
            {props.contactAttributes && props.contactAttributes.ContactID && props.contactAttributes.client_name && props.contactAttributes.sys_id && (
              <div className={styles.ccp_Info__Segment}>
                <div className={styles.ccp_Buttons}>
                <Button 
                  onClick={() => {
                    const customerNumber = props.contactAttributes.customer_number 
                      ? props.contactAttributes.customer_number.replace("+1", "") 
                      : (props.contactAttributes.callback_number 
                        ? props.contactAttributes.callback_number.replace("+1", "") 
                        : "");
                    CreateNewIncident(
                      SNowUrl, 
                      currentContactId, 
                      props.contactAttributes.client_name, 
                      customerNumber, 
                      props.contactAttributes.sys_id, 
                      agentInfo?.agentARN, 
                      props.contactAttributes.active
                    );
                  }}
>
  Create INC
</Button> 
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<input 
  type="text" 
  align="right" 
  placeholder="Enter Incident Number" 
  style={{ width: '140px' }} 
  value={incidentNumber} 
  onChange={handleInputChange} 
/>

                  <Button onClick={() => UpdateIncident(SNowUrl, incidentNumber, props.contactAttributes.client_name, props.contactAttributes.sys_id, currentContactId, agentInfo?.agentARN)}>Update INC</Button>
                  {error && <div className={styles.error_message}>{error}</div>}
                </div>
              </div>
            )}
            {props.queue && !props.contactAttributes?.client_name && !props.contactAttributes?.ContactID && !SNowUnsupported && (
              <div className={styles.ccp_Info__Segment}>
                <div className={styles.ccp_Buttons}>
                  <Button onClick={() => CreateNewIncident(SNowUrl, currentContactId, '', '', props.contactAttributes.sys_id, agentInfo?.agentARN, '')}>Create INC</Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input
                  type="text"
                  aligh="right"
                  placeholder="Enter Incident Number"
                  style={{ width: '140px' }}
                  value={incidentNumber}
                  onChange={handleInputChange}
                />
                  <Button onClick={() => UpdateIncident(SNowUrl, incidentNumber, props.contactAttributes.client_name, props.contactAttributes.sys_id, currentContactId, agentInfo?.agentARN)}>Update INC</Button>
                  {error && <div className={styles.error_message}>{error}</div>}
                </div>
              </div>
            )}
            
          {!props.queue && (
            <div className={`${styles.ccp_NoInfo}`}>
              <h3>No Data</h3>
              <h4>Waiting for Incoming Call....</h4>
            </div>
          )}
        </fieldset>
        </div>
        <div className={`${styles.ccp_Journey}`}>
        <CustomerJourney agentInfo={agentInfo} contactAttributes={props.contactAttributes} />
     </div>
     </div>
  );
};


console.log('Before const CustomerJourney');
const CustomerJourney = ({ agentInfo, ...props }) => {
  useEffect(() => {
    if (agentInfo) {
      console.log("Agent ARN (CustomerJourney): ", agentInfo.agentARN);
      // Add your logic here
    }
    else
    console.log("Agent ARN (CustomerJourney) not fetched ");
  }, [agentInfo]);
  
  const [incidents, setIncidents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isLoading, sendRequest } = useHttpClient();


  const currentContactId = useContactId(window.connect);

  console.log('Inside CustomerJourney component');

  useEffect(() => {
    const fetchIncidents = async () => {
      if (!props.contactAttributes || !props.contactAttributes.sys_id) {
        setLoading(false);
        console.log('props.contactAttributes or sys_id is undefined');
        return;
      }

      const API_GATEWAY_ENDPOINT = `https://wzcjdiknul.execute-api.us-east-1.amazonaws.com/Prod/getopenincidents?user_sys_id=${encodeURIComponent(props.contactAttributes.sys_id)}`;
      console.log('Fetching API_GATEWAY_ENDPOINT: ', API_GATEWAY_ENDPOINT);

      try {
        const response = await fetch(API_GATEWAY_ENDPOINT, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          console.log('Fetching response error: ', response.statusText);
          throw new Error(`An error has occurred: ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Fetching API_GATEWAY_ENDPOINT result: ', data);
        if (data.status === 'success') {
          setError('')
          setIncidents(data.incidents);
        } else {
          setError('Failed to fetch incidents');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchIncidents();
  }, [props.contactAttributes]);  // Update dependency array

  // Ensure contactAttributes is defined before accessing properties
  if (!props.contactAttributes) {
    console.log('contactAttributes is undefined or null');
    return (<p></p>
      /*<div>
      <fieldset className={`${styles.ccp_Info2}`}>
        <legend>
          <h1>CUSTOMER JOURNEY !!</h1>
        </legend>
      </fieldset>
      </div>
      */
    ); // or handle loading state, e.g., return <div>Loading...</div>
  }

    // Function to open a new tab with a given URL (from a list of open incidents)
  async function UpdateIncidentFromList(url, incident_number, sys_id, contact_id, agentARN) {
              const agentId = getAgentIdFromArn(agentARN);
              console.log("agentId from UpdateIncidentFromList: ", agentId);
              // Step 1: Call the Lambda function to store the contactID/incident association
              const LAMBDA_DYNAMODB_ENDPOINT = `https://wzcjdiknul.execute-api.us-east-1.amazonaws.com/Prod/storedynamodb?incidentNumber=${encodeURIComponent(incident_number)}&contactId=${encodeURIComponent(contact_id)}&sysId=${encodeURIComponent(sys_id)}&agentId=${encodeURIComponent(agentId)}`;
              const openurl = `${url}/nav_to.do?uri=incident.do%3Fsys_id=${sys_id}%26sysparm_stack=incident_list.do%3Fsysparm_query=active=true`;

              console.log('LAMBDA_DYNAMODB_ENDPOINT:', LAMBDA_DYNAMODB_ENDPOINT);

              const callLambdaFunction = async () => {
                try {
                      const lambdaResponse = await sendRequest(
                          LAMBDA_DYNAMODB_ENDPOINT,
                          'POST',
                          JSON.stringify({
                            incident_number,
                            contact_id,
                            sys_id,
                            agentId,
                          }),
                          {
                            'Content-Type': 'application/json',
                          }
                      );
          
                      // Log the lambdaResponse for debugging
                      console.log('Raw Lambda Response:', lambdaResponse);
          
                      // Check if lambdaResponse is an object with a 'result' property
                      if (!lambdaResponse || typeof lambdaResponse !== 'object' || !lambdaResponse.result) {
                        throw new Error('Invalid response from sendRequest');
                      }
          
                      // Assuming lambdaResponse.result contains the actual data
                      const lambdaData = lambdaResponse;
          
                      // Log the entire lambdaResponse for debugging
                      console.log('Parsed Lambda Response:', lambdaData);
          
                      // Check if the Lambda function executed successfully
                      if (lambdaData.result === 'Success') {
                        console.log('lambdaData.result is Success');
                        
                        console.log('url to open existing incident: ', openurl);
                        window.open(openurl, '_blank').focus();
                      } 
                      else {
                        console.error('Lambda function execution failed:', lambdaData.message || 'No error message provided');
                        window.open(openurl, '_blank').focus();
                      }
                  } 
                  catch (error) {
                    console.error('Error calling Lambda function:', error);
                    window.open(openurl, '_blank').focus();
                  }
              };
          
              // Call the Lambda function with the new incident details
              await callLambdaFunction();

  };

  // Function to convert datetime string to YYYY-MM-DD format
  const convertToYYYYMMDD = (datetimeString) => {
  const originalDate = new Date(datetimeString);
  
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
  const day = String(originalDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

  const { sys_id } = props.contactAttributes;

    // Check if sys_id is defined before using it
  if (!sys_id) {
    console.log('sys_id is undefined or null');
    return (<p></p>

    );
  }
  
  return (
  <fieldset className={`${styles.ccp_Journey2}`}>
    <legend><h1>CUSTOMER JOURNEY - OPEN INCIDENTS</h1>
    </legend>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : incidents.length === 0 ? (
            <p>No open incidents...</p>
          ) : (
            <p>
              {incidents.map((incident) => (
                <p key={incident.sys_id}>
                  <strong>Incident Number:</strong> {incident.incident_number}<br></br>
                  <strong>State:</strong> {incident.state}<br></br>
                  <strong>Created Date:</strong> {convertToYYYYMMDD(incident.created_date)}<br></br>
                  <strong>Short Description:</strong> {incident.short_description}<br></br>
                  <div className={styles.ccp_Buttons}>
                    <Button onClick={() => UpdateIncidentFromList(SNowUrl, incident.incident_number, incident.sys_id, currentContactId, agentInfo?.agentARN)}>Update INC</Button>
                  </div>
                </p>
              ))}
            </p>
          )}

  </fieldset>

  );
};

export { CCP, CustomerJourney };

